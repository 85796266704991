<template>
  <page-content>

    <common-table
      ref="table"
      path="web/profit/record"
      :columns="columns">

      <template slot="search">
        <a-button ghost type="primary" @click="add" >新增</a-button>
      </template>

      <template slot="logo" slot-scope="{record}">
        <a-avatar shape="square" :size="60" :src="record.logo">{{record.title}}</a-avatar>
      </template>

      <template slot="operation" slot-scope="{record}">
        <action-edit  @click="edit(record)"></action-edit>
        <action-delete  @click="remove(record)"></action-delete>
      </template>

      <template slot="status" slot-scope="{record}">
        <a-tag v-if="record.status==0" color="cyan">待付款</a-tag>
        <a-tag v-else color="green">已付款</a-tag>
      </template>

    </common-table>

    <record-edit ref="editDrawer" @success="getList"></record-edit>
  </page-content>
</template>
<script>
  import RecordEdit from './RecordEdit'
  export default {
    components: {
      RecordEdit
    },
    data () {
      return {

      }
    },

    computed: {
      columns () {
        return [
          {
            title: '项目',
            dataIndex: 'projectName',
          },
          {
            title: '金额',
            dataIndex: 'money',
            customRender: (text, row, index) => {
              return text/100
            },
          },
          {
            title: '备注',
            dataIndex: 'remark'
          },
          {
            title: '时间',
            dataIndex: 'createdAt'
          },
          // {
          //   title: '状态',
          //   dataIndex: 'status',
          //   scopedSlots: { customRender: 'status' }
          // }
        ]
      }
    },
    methods: {
      add () {
        this.$refs.editDrawer.show()
      },
      edit (record) {
        this.$refs.editDrawer.show(record)
      },
      remove (record) {
        var that = this
        this.$confirm({
          title: '是否删除记录?',
          content: '该操作不可撤销',
          centered: true,
          onOk () {
            that.$delete('web/profit/project' + record.id).then(() => {
              that.$message.success('删除成功')
              that.getList()
            })
          }
        })
      },
      getList () {
        this.$refs.table.getData()
      }

    }
  }
</script>
<style lang="less" scoped>

  .news-logo {
    width: 60px;
    height: 60px;
  }
</style>
